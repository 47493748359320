var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ptxt" }, [
      _c("p", { staticStyle: { "text-align": "center" } }, [
        _c("strong", [_vm._v("隐私权政策")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v("创建日期：2022.12.20"),
          _c("br"),
          _vm._v("生效日期：2022.12.20"),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("APP应用名称")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v("随心语音"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("特别提示")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [
          _vm._v(
            "【广西牧纯科技有限公司】（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "在使用本产品和服务前，请您务必仔细阅读并透彻理解本隐私政策，在确认充分理解并同意后使用相关产品或服务。如果您不同意本隐私政策任何内容，您应立即停止使用本产品或服务。若您使用本产品或服务，即表示您已充分理解并同意我们在本隐私政策中所述内容。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("一、我们如何收集您的个人信息"),
          _c("br"),
          _vm._v("二、我们如何使用您的个人信息"),
          _c("br"),
          _vm._v("三、我们如何委托处理、共享、转让、公开披露您的个人信息"),
          _c("br"),
          _vm._v("四、我们如何 保存和保护 您的个人信息"),
          _c("br"),
          _vm._v("五、Cookie 的使用"),
          _c("br"),
          _vm._v("六、访问、修改和删除信息"),
          _c("br"),
          _vm._v("七、我们如何处理未成年人的个人信息"),
          _c("br"),
          _vm._v("八、隐私政策 特别条款"),
          _c("br"),
          _vm._v("九、隐私政策的更新"),
          _c("br"),
          _vm._v("十、管辖与适用"),
          _c("br"),
          _vm._v("十一、如何联系我们"),
        ]),
      ]),
      _c("p", [
        _c("strong", [
          _c("br"),
          _vm._v("一、我们如何收集和使用您的个人信息"),
          _c("br"),
          _vm._v(
            "我们根据合法、正当、必要的原则，为实现本政策下述的各项功能，收集和使用您的个人信息："
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（一）您向我们提供的信息。")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("1、您在我们平台或产品上注册账户时候提交的信息"),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "当您注册或登录账号时，您可以通过手机号创建账号，我们将通过发送短信验证码来验证您的身份是否有效，您可以主动完善相关的网络身份识别信息（如头像、昵称和密码），可以填写"
          ),
        ]),
        _c("strong", [
          _vm._v("性别、星座、个性签名、语音介绍、头像照片、昵称"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("来完善您的个人资料。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "您也可以使用第三方账号（如微信、QQ）登录随心语音平台，此时您将授权我们获取您在第三方平台上的公开信息（头像、昵称），并将您的该第三方账号与您的随心语音账号绑定，使您可以通过该第三方账号直接登录并使用随心语音的产品与服务，并获取"
          ),
        ]),
        _c("strong", [_vm._v("MAC地址")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "用于服务端对客户端的安全校验，保证您的账号和设备安全。完成注册后，您将能够以注册用户身份在随心语音平台上使用音频互动、发送弹幕、留言评论等功能。如果您不同意授权或拒绝我们获取上述信息，您仍可在随心语音平台上以游客身份收听音频直播、观看弹幕或进行浏览，但将无法在享受其他注册用户的功能和服务。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("2、使用直播相关功能或服务时")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "在您使用直播相关功能或服务时（包括但不限于提供音频直播、使用音频直播打赏功能及服务）时，根据国家相关的法律法规，"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "您需要提供您的真实身份信息（真实姓名、身份证号）、面部信息（用于人脸识别）以完成实名验证。"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "这些信息属于个人敏感信息，我们将会尽可能的保护您个人敏感信息的安全。如果您不提供这些信息，您将不能使用直播功能或服务，但不影响您在 随心语音 平台上享受视频浏览以及注册用户的基础服务。"
          ),
        ]),
        _c("strong", [
          _vm._v("未成年人用户不得使用随心语音平台上的打赏功能及服务。"),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("3、向我们提交的信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("您在使用随心语音产品与服务时填写或提交的信息，包括您的"),
        ]),
        _c("strong", [
          _vm._v(
            "姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、地址、兴趣爱好、银行账号及相关附加信息（如您所在的省份和城市、邮政编码）"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("4、用户信息不得有以下情形"),
          _c("br"),
          _c("br"),
          _vm._v("（一）违反《网络信息内容生态治理规定》第六条、第七条规定；"),
          _c("br"),
          _vm._v(
            "（二）假冒、仿冒、捏造政党、党政军机关、企事业单位、人民团体和社会组织的名称、标识等；"
          ),
          _c("br"),
          _vm._v(
            "（三）假冒、仿冒、捏造国家（地区）、国际组织的名称、标识等；"
          ),
          _c("br"),
          _vm._v(
            "（四）假冒、仿冒、捏造新闻网站、报刊社、广播电视机构、通讯社等新闻媒体的名称、标识等，或者擅自使用“新闻”、“报道”等具有新闻属性的名称、标识等；"
          ),
          _c("br"),
          _vm._v(
            "（五）假冒、仿冒、恶意关联国家行政区域、机构所在地、标志性建筑物等重要空间的地理名称、标识等；"
          ),
          _c("br"),
          _vm._v(
            "（六）以损害公共利益或者谋取不正当利益等为目的，故意夹带二维码、网址、邮箱、联系方式等，或者使用同音、谐音、相近的文字、数字、符号和字母等；"
          ),
          _c("br"),
          _vm._v(
            "（七）含有名不副实、夸大其词等可能使公众受骗或者产生误解的内容；"
          ),
          _c("br"),
          _vm._v("（八）含有法律、行政法规和国家有关规定禁止的其他内容。"),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "请注意： 我们的多项服务，可让您不仅与自己的社交网络分享信息，也与使用该服务的所有用户公开分享您的相关信息，您在我们平台上传或发布的信息（包括您公开的个人信息、您建立的名单）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的"
          ),
        ]),
        _c("strong", [_vm._v("位置数据、视频、音频信息。")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("使用我们服务的其他用户也有可能分享与您有关的信息（包括"),
        ]),
        _c("strong", [_vm._v("文字、图像、音频信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "）。特别是，我们的社交媒体服务，是专为使您与世界各地的用户共享信息而设计，您可以使共享信息实时、广泛地传递。 只要您不删除共享信息，有关信息会一直留存在公共领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或第三方在公共领域保存。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "因此，请您谨慎考虑通过随心语音产品与服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请和我们取得联系。"
          ),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（二）在您使用服务过程中收集的信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "为了更好的为您提供服务，我们会收集您的相关信息，这类信息包括："
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("1、设备的硬件信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "为保障您正常使用随心语音平台的基础功能和正常服务、改进及优化我们的服务体验、保障您的帐号安全，我们会自动接收并记录您的"
          ),
        ]),
        _c("strong", [_vm._v("浏览器、计算机、移动设备、终端设备")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("相关的信息，包括但不限于您的"),
        ]),
        _c("strong", [
          _vm._v(
            "IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您的网页记录"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "数据；如您下载或使用我们或我们关联公司客户端软件，或访问移动网页使用我们平台的服务时，我们可能会读取与您位置和终端设备相关的信息，包括但不限于 "
          ),
        ]),
        _c("strong", [
          _vm._v("设备型号、设备识别码、操作系统、分辨率、电信运营商"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "。 请您了解，这些信息是我们提供服务和保障服务正常运行所必须收集的基本信息。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("2、设备里的文件信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "我们会收集您通过随心语音平台自行上传或发布的内容、信息，包括"
          ),
        ]),
        _c("strong", [
          _vm._v("文字、共享照片、录音、录像及该类信息的日期、时间或地点"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。当您使用上述功能时，我们会向您请求"),
        ]),
        _c("strong", [
          _vm._v("授权相机、照片、麦克风权限和设备所在位置信息权限"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "。您可以自行选择是否向我们提供该授权，如果拒绝，将无法使用此项功能，但不影响您使用随心语音平台的其他功能。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("3、为提高用户体验，我们需要收集的信息"),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "（1）关注、搜索、浏览记录等您的操作、使用行为信息； 为此，我们会收集您使用随心语音平台时的设备信息，包括 "
          ),
        ]),
        _c("strong", [
          _vm._v(
            "设备型号、操作系统版本、设备设置、IMEI/MEID/IDFA/IDFV/OAID/MAC地址/IMSI/ICCID/UDID/Android_ID/GUID即设备标识符、设备序列号、设备环境、读取剪切板内容信息、操作系统、分辨率、应用列表、数据连接状态、接入网络的方式（类型、状态）和电信运营商软硬件信息。"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "（2） 反馈、发布、点赞、评论、打赏 这您主动提供的互动信息会存储在我们的服务器中；"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("4、提供充值消费相关的功能或服务")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("当您使用随心语音产品与服务的消费功能时，我们会收集您的"),
        ]),
        _c("strong", [_vm._v("充值记录、消费记录信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "，以便您查询自己的交易记录，同时尽最大程度保护您的财产、虚拟财产安全。上述信息属于敏感信息，但收集上述信息为实现相关功能所必须，否则将无法完成交易。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("5、为保证您的安全，我们需要收集的信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "除上述信息外，为了改进服务质量，我们还可能收集您的其他信息，包括且并不限于您与我们的客户服务团队联系时"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联公司、合作方互动时提供的相关信息"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "。与此同时，为提高您使用相关服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("6、自启动或关联启动说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "为了使您能够正常使用我们的产品及/或服务，我们可能根据特定业务场景自行启动第三方应用。例如，当您使用APP“分享”功能时，QQ、微信、新浪微博等第三方应用将可能自行启动；当您使用“支付”功能时，支付宝、微信等第三方应用将可能自行启动；当您使用APP涉及到“系统”功能时，相机、图库、设置页等第三方应用将可能自行启动；IM功能需要，如果关闭可能会导致IM部分功能不可用，如消息发送、接收等。如您使用Android系统，您可以选择关闭自启动权限，iOS系统则不涉及自启动权限的问题。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（三）我们可能会申请获取的设备权限")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "我们在提供服务的过程中，为了收集上述信息、实现前述功能，可能需要向您申请部分设备应用权限。这些权限不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。同时请您知悉，我们为业务与产品的功能与安全需要，会使用第三方SDK，您也可能会在我们的产品过程中使用第三方开发的H5页面或小程序，这些第三方也可能会申请或使用相关操作系统权限。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "1. 为保障应用能实现与安全稳定运行之目的，我们可能会申请或使用操作系统的相关权限；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2. 保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3. 根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4. 请你知悉，我们为业务与产品的功能与安全需要，会使用第三方SDK，您也可能会在我们的产品过程中使用第三方开发的H5页面或小程序，这些第三方也可能会申请或使用相关操作系统权限"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("安卓操作系统应用权限列表")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("1、读取SD卡中的内容（读取存储空间/照片权限）")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：提供读取手机储存空间内数据的功能"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：允许App读取存储中的图片、文件等内容，主要用于帮助您发布动态，上传头像等图片、音视频文件，私信发送图片消息，在本地记录崩溃日志信息等功能"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("2、写入/删除SD卡中的内容（写入/删除/存储空间/照片权限）"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：提供写入外部储存功能"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：允许App写入/下载/保存/缓存/修改/删除图片、文件、崩溃日志等信息"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("3、读取手机状态和身份（电话权限、设备权限）")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：读取设备通话状态和识别码，识别设备ID，用于完成一键登陆、信息展示、账号登录、安全保障等主要功能"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_vm._v("4、")]),
        _c("strong", [_vm._v("录音 android.permission.RECORD_AUDIO")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：使用麦克风录制音频"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：用于帮助您完成音频信息发布、进行语音直播、发送语音消息等需要使用该权限的相关功能"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("5、拍摄照片和视频 android.permission.CAMERA"),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：使用拍摄照片和视频"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：用于帮助您完成照片或视频信息发布、拍摄头像照片等需要使用该权限的相关功能"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("6、通知权限")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：使用系统通知提醒"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：用于帮助您收到系统和好友给您发送的消息通知"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("iOS操作系统应用权限列表")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("1、NSPhotoLibraryAddUsageDescription")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：向相册中添加内容"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("2、NSPhotoLibraryUsageDescription")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：读取相册中内容"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息等功能"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("3、NSCameraUsageDescription")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：使用摄像头"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：人脸核身"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("4、NSMicrophoneUsageDescription")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：使用麦克风"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：用于帮助您完成连麦、语音输入等功能"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("5、NSUserTrackingUsageDescription")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：获取设备标识，以识别设备信息"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：仅用于标识设备并保障服务安全与提升浏览体验"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("6、NSAppleMusicUsageDescription"), _c("br")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：媒体资料库"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：用于帮你实现在聊天室进行播放背景音乐等功能"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("7、NSLocalNetworkUsageDescription"), _c("br")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("权限功能说明")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("：网络权限"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("使用场景与目的")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "：用于检测与您本地网关的连通性，不会连接到您所用网络上的设备"
          ),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("（四）我们从第三方获得您个人信息的情形")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v("为了给您提供更好的服务、响应国家对于"),
        ]),
        _c("strong", [_vm._v("未成年人")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "保护等要求、或为了预防互联网犯罪，我们的关联公司、合作伙伴会依据法律法规、监管政策的规定或征得您同意的前提下，向我们分享您的个人信息。我们可能从第三方获取您授权共享的账号信息"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "（头像、昵称）及帐号绑定和认证情况（手机号码、是否成年状态）"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "，并在您同意本隐私政策后将您的第三方账户与您的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律法规、监管政策规定的前提下，使用您的这些个人信息。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "由于向您提供服务或保护您或其他用户的权益而需要验证您的真实身份时，又或者法律强制性规定或有权的行政或司法机关依法要求我们提供您的个人信息时，如果您的真实身份信息（包括但不限于"
          ),
        ]),
        _c("strong", [_vm._v("真实姓名、身份证号")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "）由前款所述的第三方持有，我们可能会从该等第三方获取您的真实身份信息，并可能按照以下第三条描述的方式使用和处置该等信息。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（五）第三方SDK可能收集的信息")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "您理解并认可，为提供服务之目的，产品通过接入第三方服务实现部分技术功能（如嵌入代码、插件、SDK等形式），上述第三方服务仅能接触到其履行职责所需用户个人信息，并保证将采取行业领先的技术来保护您的个人信息安全。请您查阅第三方的隐私政策。您同意当您使用这些产品或服务时，第三方根据其隐私政策收集和使用您的信息。您同意我们和第三方在符合法律法规的前提下可将收集的信息用于其他服务和用途。需要手机和使用您信息的第三方的服务名称、功能、收集个人信息类型和隐私政策链接如下："
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("第三方SDK目录")])]),
      _c("p", [_c("strong")]),
      _c("p", [_c("strong", [_vm._v("声网SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "网络类型、用户本地IP地址、WIFI和蜂窝网信号状态、操作系统类型、机型信息、CPU信息、内存信息、设备电量、音频流数据、视频流数据、蓝牙、网络延时、丢包率、视频帧率、码率、分辨率、音频码率、采样率"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("连麦、推流、音视频功能"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [_c("u", [_vm._v("https://www.agora.io/cn/privacy-policy")])]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("融云SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "Wi-Fi信息、设备标识信息、地理位置信息、运营商信息、外部存储设备、当前运行进程、屏幕参数"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("使用IM"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [_c("u", [_vm._v("https://netease.im/clauses?serviceType=3")])]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("支付宝SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "摄像头权限、网络状态、网络信息状态、WIFI状态、手机状态、读写存储空间、改变Z轴排列任务"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("帮助用户完成支付宝支付、提供安全认知服务"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://render.alipay.com/p/f/fd-jm7jym6r/alipay/multi-agreement.html"
          ),
        ]),
      ]),
      _c("p", [
        _c("u", [
          _vm._v("https://zm.zmxy.com.cn/p/f/privacypolicy/index.html"),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("创蓝闪验SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "电话状态，网络访河，WiFi网络状态，网络状态，更改网络连接状态，运营商信息，设备信息，读写系统设置项，访问TASK、获取已安装APP信息，获取应用安装列表"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("实现“一键登录功能”"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://shanyan.253.com/document/details?lid=639&cid=91&pc=28&pn=%E9%97%AA%E9%AA%8CSDK"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("中国移动")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            '获取设备标识符 (IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM 卡IMSI信息)，网络访问，获取WiFi登录"功能状态，手机号码，应用列表信息'
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("实现“一键登录功能”"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v("https://wap.cmpassport.com/resources/html/contract.html"),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("中国电信")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            '设备标识符 (MEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM 卡IMSI信实现"—键登录”功能息），网络访问，获取WiF状态，手机号码'
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("实现“一键登录功能”"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=true&returnUr"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("中国联通")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            '设备标识符 (MEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM 卡IMSI信实现"—键登录”功能息），网络访问，获取WiF状态，手机号码'
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("实现“一键登录功能”"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("友盟SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "网络信息（如Wi-Fi信息）、设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡/IMSI/获取设备序列号)、位置信息、运营商信息、外部存储设备信息、屏幕参数、当前运行进程、获取已安装APP信息"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("提供数据统计、数据收集和数据分析"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [_c("u", [_vm._v("https://www.umeng.com/policy")])]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("微信开放平台SDK")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "设备标识符，设备MAC地址，运营商信息，位置信息，Wi-Fi地址，获取应用安装列表"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("为用户提供微信登录、分享、支付功能"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/ONwPihxKd82RAkIJ"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("阿里云HttpDNS")])]),
      _c("p", [
        _c("strong", [_vm._v("涉及个人信息：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "设备信息，包括IMEI，Android系统设备标识运营商信息，网络信息包括netType，SSID。"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("使用目的：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "用于实现HTTPDNS域名解析服务的基本功能，在网络切换时缓存域名解析结果，用于统计。"
          ),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("收集方式：")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("SDK采集"),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("合作方隐私政策：")])]),
      _c("p", [
        _c("u", [
          _vm._v(
            "https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("二、我们如何使用您的信息")])]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v("为了提高服务质量，我们可能把您的信息用于下列用途："),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("1、向您提供服务；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "2、用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3、我们可能会将您的个人信息与其他服务信息结合起来，用于为了给您提供服务使用，例如让您拥有更广泛的社交圈的需要而使用、共享或披露；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("4、让您参与有关我们产品和服务的调查，促销及抽奖活动；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "5、使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的需求，例如语言设定、位置设定的帮助服务和指示或对您和其他用户作出其他方面的回应；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("6、软件认证或管理软件升级；"),
          _c("br"),
          _c("br"),
          _vm._v("7、让您参与有关我们产品和服务的调查；"),
          _c("br"),
          _c("br"),
          _vm._v("8、经您许可的其他用途"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_vm._v("。")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("三、我们如何委托处理、共享、转让、披露您的信息"),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("（一）委托处理")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "随心语音平台上提供的直播业务功能中某些具体的模块或功能由外部供应商提供。例如 我们会聘请服务提供商来协助我们对"
          ),
        ]),
        _c("strong", [_vm._v("实名认证用户完成人脸识别")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（二）共享")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "1. 与关联公司间共享：为便于我们基于随心语音账户向您提供产品和服务，识别会员账号异常，保护随心语音关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司或其指定的服务提供商共享。"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意；"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "2.与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v("目前，我们的授权合作伙伴包括以下类型："),
          _c("br"),
          _c("br"),
          _vm._v(
            "（1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，但不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以进行广告或决策建议；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "（2）软件发行及线上支付渠道类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与此类授权合作伙伴分享。但是，为解决您在获取软件、充值消费过程中遇到的困难，或者核实您的充值消费或申请退款行为的合法性，必要时我们会向此类合作伙伴提供经过匿名化处理后的您的消费记录和相关操作记录，确保无法从这些记录中直接识别您的个人身份。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "（3）供应商、服务提供商、联合运营方和其他合作伙伴。我们将信息发送给支持或参与我们业务的供应商、服务提供商、联合运营方和其他合作伙伴，这些支持和参与包括提供技术基础设施服务、就联合运营的收益进行对账和结算、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查等。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "我们会与其约定严格的数据保护措施，令其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（三）转让")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外："
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2. 满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3. 如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移， 我们将按照法律法规规定确保该等信息在转移时的机密性，并尽最大可能确保个人信息的继受方继续受本隐私政策的约束，保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（四）披露")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "我们仅会在以下情况下，披露您的个人信息，该等信息可能包括真实身份信息等您的个人敏感信息："
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "1. 获得您明确同意或基于您的主动选择，我们可能会披露您的个人信息；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2. 如果我们确定您出现违反法律法规、监管政策或严重违反随心语音平台相关协议及规则的情况，或为保护其他用户或公众的人身财产安全免遭侵害，我们可能依据法律法规、监管政策或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及随心语音平台已对您采取的措施。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3. 法律强制性规定或者有权的行政或司法机关依法要求应当披露您的个人信息时。由于第三方登录、联合运营等合作安排，可能导致我们负有义务通过合作方完成前述披露。在该种情况下，该等合作方亦会知悉您的这些个人信息。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("四、我们如何保存和保护您的个人信息")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("（一）您个人信息的保存")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("1、保存期限"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "您在使用随心语音产品与服务期间，我们将持续为您保存您的个人信息"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "。如果您注销帐户或主动删除上述信息，我们将依据《网络安全法》等法律法规、监管政策的规定保存您的信息。"
          ),
        ]),
        _c("strong", [
          _vm._v(
            "在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v("2、保存地域"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "您的个人信息均储存于可适用的法律所允许的地理位置，以来自中国大陆地区的用户为例，其个人信息均会被存储在位于中国大陆地区的服务器上"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "。除非法律要求或许可， 我们不会对您的个人信息进行跨境传输。如果您使用VPN等可能隐藏您真实地理位置的技术，或者遇到域名或IP地址解析不准确的客观情况，您可能会被连接到上述地理位置以外的服务器上，此时您的个人信息将可能发生跨境传输。"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [
        _c("strong", [_vm._v("（二）您个人信息的保护")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "1、为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于 SSL 、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄漏、毁损或丢失。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2 、 您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在 “完善的安全措施 ” 。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3 、 在使用我们平台服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如银行账户信息、联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即联络我们客服，以便我们采取相应措施。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（三）安全事件通知")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "1 、 我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2 、 个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3 、 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话或邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("五、Cookie的使用")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "为使您获得更轻松的访问体验，您访问我们平台网站或使用我们平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是 Cookie， Flash Cookie ，您的浏览器或关联应用程序提供的其他本地存储（统称 “Cookie” ）。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "请您理解，我们的某些服务只能通过使用“Cookie” 才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对 Cookie 的接受程度或者拒绝我们的 Cookie ，但这一举动在某些情况下可能会影响您安全访问我们平台相关网站和使用我们平台提供的服务。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "我们的产品和服务上可能会有广告商或其他合作方放置的cookies和 web beacon 。这些 cookies 和 web beacon 可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务，或用于评估广告服务的效果。这些第三方 cookies 和 web beacon 收集和使用该等信息，不受本隐私政策约束，而是受相关使用者的隐私政策约束，我们不对第三方的 cookies 或 web beacon 承担责任。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "您可以通过浏览器设置拒绝或管理cookies或 web beacon 。但请注意，如果停用 cookies 或 web beacon ，您有可能无法享受佳的服务体验，某些服务也可能无法正常使用。同时，您还会收到同样数量的广告，但这些广告与您的相关性会降低。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("六、访问、修改和删除信息")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "我们将按照本隐私政策所述，仅为实现我们产品或服务的功能，收集、使用您的信息。如您发现我们违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我们删除。如您发现我们收集、存储的您的个人信息有错误的，您也可以要求我们更正。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("（一）您可以通过以下方式访问、修改或删除自己的信息"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("："),
          _c("br"),
          _c("br"),
          _vm._v(
            "1. 登录随心语音APP，进入“我的”→（点击头像）→（右上角编辑），对个人资料等信息进行访问、修改或删除；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "2. 登录随心语音APP，进入“我的”→（设置按钮）→（账号安全），选择对应的设置选项，对账号与安全、隐私设置等信息进行访问、修改或删除；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("3. 按照本隐私政策文末所列明的公开联系方式，与我们取得联系。"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（二）改变您授权同意的范围")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "每个业务功能需要一些基本的个人信息才能得以完成。您可以通过改变手机、平板电脑等智能移动设备的设置或通过上方列举的方式向我们给予或收回授权同意。当您收回相关的授权同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（三）我们如何响应您的上述请求")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "在您访问、修改或删除相关信息或账号时，我们可能会要求您进行身份验证，以保障帐号的安全。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。同时，按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求："
          ),
          _c("br"),
          _c("br"),
          _vm._v("1. 与国家安全、国防安全直接相关的；"),
          _c("br"),
          _c("br"),
          _vm._v("2. 与公共安全、公共卫生、重大公共利益直接相关的；"),
          _c("br"),
          _c("br"),
          _vm._v("3. 与犯罪侦查、起诉、审判和执行判决等直接相关的；"),
          _c("br"),
          _c("br"),
          _vm._v("4. 有充分证据表明您存在主观恶意或滥用权利的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "5. 响应您的请求将导致其他个人、组织的合法权益受到严重损害的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("6. 涉及商业秘密的。"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("（四）个人信息主体注销账户")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("："),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v("在APP首页依次点击“我的”->“设置”->“账号安全”->“注销帐号”"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "，查看注销帐号须知后，可继续申请注销，输入手机号和验证码后点下一步。提交申请后，工作人员会对帐号进行核实审核，审核成功后，在5个工作日内处理注销操作。注销成功后，需用户自行点击确认，注销成功"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "在注销账户之后，我们将停止为您提供产品服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("七、未成年人隐私权特别约定")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "我们要求父母或监护人在阅读本隐私政策后指导未满十八岁（或可适用的法律规定的其他成年年龄，一般取决于用户的国籍和当前所在国家／地区）的"
          ),
        ]),
        _c("strong", [_vm._v("未成年人")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("使用我们的服务。"),
          _c("br"),
          _c("br"),
          _vm._v("我们不会在知情的情况下故意收集"),
        ]),
        _c("strong", [_vm._v("未成年人")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("的个人信息。除非所在地法律允许并且监护人同意，"),
        ]),
        _c("strong", [
          _vm._v(
            "未成年人请不要注册账户或发送自己的姓名、住址、电话、邮件地址等个人信息给我们。"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "如果我们不小心收集到了未成年人的信息，我们在知情后会尽快删除。如果您认为我们可能不当地持有关于未成年人的信息，请按照本隐私政策篇末提供的方式联系我们。"
          ),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong", [_vm._v("八、隐私政策特别条款")])]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [
          _vm._v(
            "请您充分知晓，以下情形下，我们收集、使用个人信息依法不再另行征得您的授权同意："
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("1、与国家安全和社会公共利益有关的；"),
          _c("br"),
          _c("br"),
          _vm._v("2、与犯罪侦查、起诉、审判和判决执行等有关的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "3、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("4、所收集的个人信息是个人信息主体自行向社会公众公开的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "5、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6、违反法律规定或违反我们平台规则导致我们已对您采取必要措施；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("7、根据您的要求签订合同所必需的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("9、为合法的新闻报道所必需的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "10、术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("11、法律法规规定的其他情形。"),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("九、隐私政策的变更")]),
      ]),
      _c("p", [
        _c("strong", [
          _c("br"),
          _vm._v(
            "我们可能适时修订本隐私政策的条款，该修订构成本隐私政策的一部分。 如该等修订造成您在本隐私政策下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。 在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私政策的约束。根据您具体使用的随心语音产品与服务以及您所使用的设备的不同，本隐私政策的条款可能有所差异，请以您具体使用的随心语音产品与服务对应您的设备的版本中提供的本隐私政策文本为准。"
          ),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("十、管辖与法律适用")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [
          _vm._v(
            "本隐私政策的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律，并明确排除其冲突法规范的适用。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "若您和我们发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交上海互联网法院通过诉讼解决；纠纷事由依法不属于上海互联网法院受理范围的，应提交本隐私政策签订地有管辖权的人民法院管辖。"
          ),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("十一、如何联系我们")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v("您可以通过以下方式与我们联系："),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "1、如对本隐私政策内容有任何疑问、意见或建议，您可以联系我们的在线客服；或者可以发送邮件至82002598@QQ.COM。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "注：本《隐私权保护政策》版本更新日期为 2022 年 12 月 20 日，于 2022 年 12 月 20 日正式生效 。"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }